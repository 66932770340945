<template>
    <div class="create-consignment-notes-registry">

        <PageLoader v-if="!pageLoad" />

        <div class="page-title d-flex py-2 justify-content-between">

            <h1
                v-if="edit"
                class="h4 mb-0">
                Редактирование реестра товарной накладной <span v-if="currentConsignmentNotesRegistry">№ {{ currentConsignmentNotesRegistry.number }} от {{ currentConsignmentNotesRegistry.date | parseDate }}</span>
            </h1>
            <h1
                v-else
                class="h4 mb-0">
                Создание реестра товарной накладной
            </h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="danger"
                    v-b-modal.confirm-delete>Удалить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="success"
                    @click="createConsigmentNoteRegistry(true)">Сохранить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    @click="createConsigmentNoteRegistry()"
                >Отправить на согласование
                </BButton>
            </div>

        </div>

        <b-tabs v-model="tabIndex" card>

            <b-tab title="Информация">

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Филиал заказчик</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <b-form-select
                            v-model="sendData.organization_id"
                            :state="$v.sendData.$error ? $v.sendData.organization_id.required : null"
                            :options="organizationsOptions.slice(1)"
                        />
                    </div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Подрядчик</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <div
                            v-if="isContractor"
                            style="height: 29px;line-height: 29px;"
                        >{{ user.contr_agent.name }}
                        </div>
                        <!-- <b-form-select
                            v-if="isProvider"
                            v-model="sendData.contractor_contr_agent_id"
                            :state="$v.sendData.$error ? $v.sendData.contractor_contr_agent_id.required : null"
                            :options="contrAgentsOptions.slice(1)"
                        /> -->
                        <v-select
                            v-if="isProvider"
                            label="text"
                            v-bind:class="{ 'v-select-error': $v.sendData.$error && $v.sendData.contractor_contr_agent_id.required }"
                            v-bind:appendToBody="true"
                            v-bind:options="contrAgentsOptions.slice(1)"
                            v-bind:placeholder="contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id ) ? contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id ).text : ''"
                            v-bind:value="contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id )"
                            v-on:input="value => ( sendData.contractor_contr_agent_id = value.value )"
                            @search="getContrAgentsOptionsByQuery"
                        >
                            <template #no-options>
                                Нет результатов.
                            </template>
                        </v-select>
                    </div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Поставщик</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <div
                            v-if="isProvider"
                            style="height: 29px;line-height: 29px;"
                        >{{ user.contr_agent.name }}
                        </div>
                        <!-- <b-form-select
                            v-if="isContractor"
                            v-model="sendData.provider_contr_agent_id"
                            :state="$v.sendData.$error ? $v.sendData.provider_contr_agent_id.required : null"
                            :options="contrAgentsOptions.slice(1)"
                        /> -->
                        <v-select
                            v-if="isContractor"
                            label="text"
                            v-bind:class="{ 'v-select-error': $v.sendData.provider_contr_agent_id.$error }"
                            v-bind:appendToBody="true"
                            v-bind:options="contrAgentsOptions.slice(1)"
                            v-bind:placeholder="contrAgentsOptions.slice(1).find( el => el.value === sendData.provider_contr_agent_id ) ? contrAgentsOptions.slice(1).find( el => el.value === sendData.provider_contr_agent_id ).text : ''"
                            v-bind:value="contrAgentsOptions.slice(1).find( el => el.value === sendData.provider_contr_agent_id )"
                            v-on:input="value => ( sendData.provider_contr_agent_id = value.value )"
                            @search="getContrAgentsOptionsByQuery"
                        >
                            <template #no-options>
                                Нет результатов.
                            </template>
                        </v-select>
                    </div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Объект</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <!-- <b-form-select
                            v-model="sendData.customer_object_id"
                            :state="$v.sendData.$error ? $v.sendData.customer_object_id.required : null"
                            :options="objectsOptions"
                        /> -->
                        <v-select
                            label="text"
                            v-bind:class="{ 'v-select-error': $v.sendData.customer_object_id.$error }"
                            v-bind:options="objectsOptions"
                            v-bind:value="objectsOptions.find( el => el.value === sendData.customer_object_id )"
                            v-on:input="value => ( sendData.customer_object_id = value.value )"
                            :appendToBody="true"
                            :placeholder="objectsOptions.find( el => el.value === sendData.customer_object_id ) ? objectsOptions.find( el => el.value === sendData.customer_object_id ).text : ''"
                            @search="getObjectsOptionsByQuery"
                        >
                            <template #no-options>
                            Нет результатов.
                            </template>
                        </v-select>
                    </div>
                </div>

                <div
                    v-if="subObjectsOptions(sendData.customer_object_id) && subObjectsOptions(sendData.customer_object_id).length"
                    class="d-flex table-bordered mb-1"
                >
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >Подобъект
                    </div>
                    <div class="px-2 py-1 flex-grow-1">
                        <b-form-select
                            v-model="sendData.customer_sub_object_id"
                            :options="subObjectsOptions(sendData.customer_object_id)"
                        />
                    </div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >Договор подряда
                    </div>
                    <div class="px-2 py-1 flex-grow-1">
                        <!-- <b-form-select
                            v-model="sendData.work_agreement_id"
                            :state="$v.sendData.$error ? $v.sendData.work_agreement_id.required : null"
                            :options="workAgreementsOptions.slice(1)"
                        /> -->
                        <v-select
                            label="text"
                            v-bind:class="{
                            'v-select-error':
                                $v.sendData.$error && $v.sendData.work_agreement_id.required,
                            }"
                            v-bind:appendToBody="true"
                            v-bind:options="workAgreementsOptions.slice(1)"
                            v-bind:placeholder="
                            workAgreementsOptions
                                .slice(1)
                                .find(
                                (el) => el.value === sendData.work_agreement_id
                                )
                                ? workAgreementsOptions
                                    .slice(1)
                                    .find(
                                    (el) =>
                                        el.value === sendData.work_agreement_id
                                    ).text
                                : ''
                            "
                            v-bind:value="
                            workAgreementsOptions
                                .slice(1)
                                .find(
                                (el) => el.value === sendData.work_agreement_id
                                )
                            "
                            v-on:input="
                            (value) => (sendData.work_agreement_id = value.value)
                            "
                            @search="getWorkAgreementsOptionsByQuery"
                        >
                            <template #no-options> Нет результатов. </template>
                        </v-select>
                    </div>
                </div>

                <b-row>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Ответственный
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <b-form-input
                                    v-model="sendData.responsible_full_name"
                                    :state="$v.sendData.$error ? $v.sendData.responsible_full_name.required : null"
                                    trim/>
                            </div>
                        </div>
                    </b-col>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Телефон
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <b-form-input
                                    v-model="sendData.responsible_phone"
                                    :state="$v.sendData.$error ? $v.sendData.responsible_phone.required : null"
                                    v-mask="'+7 (###) ###-##-##'"
                                    trim/>
                            </div>
                        </div>
                    </b-col>

                </b-row>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Комментарий</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <b-form-textarea
                            v-model.trim="sendData.comment"
                            :state="$v.sendData.$error ? $v.sendData.comment.required : null"
                            placeholder="Текст комментария"/>
                    </div>
                </div>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Приложение</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <b-form-file
                            v-model="sendData.file"
                            placeholder="Выберите файл"
                            plain
                        />
                        <div
                            v-if="fileUrl"
                            class="mt-2"
                        >
                            Ранее загруженный файл:
                            <DownloadLink
                                :id="id"
                                from="price-negotiations"
                                :url="fileUrl"
                            />
                            <div class="mt-1">
                                <a
                                    href="#"
                                    class="text-danger"
                                    @click="clearFile"
                                >
                                    <b-icon-trash/>
                                    Удалить
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab title="Позиции">

                <CreateTable
                    v-if="consignments && consignments.length"
                    v-model="positions"
                    :fields="positionsFields"
                    :withIterator="true"
                    :consignments="consignments"
                    @addRow="addConsignmentNotesRegistryPosition"
                />

                <div
                    v-else
                    class="text-center mt-3 h5 text-danger"
                >
                    По выбранным данным не найдено ни одной товарной накладной. Пожалуйста проверьте правильность введенных данных.
                </div>

            </b-tab>

        </b-tabs>

        <b-modal
            id="confirm-delete"
            title="Удаление реестра товарных накладных"
            centered>
            <p class="m-0">Вы действительно хотите удалить реестр товарных накладных?</p>
            <template #modal-footer="{ hide }">
                <b-button @click="hide()">Нет</b-button>
                <b-button
                    variant="danger"
                    @click="deleteConsigmentNoteRegistry">Да
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CreateTable from "@/components/Tables/Create"
import PageLoader from "@/components/UI/PageLoader";
import { debounce } from "debounce";
import axios from 'axios'

export default {
    name: "ConsignmentNoteRegistryForm",
    components: {
        PageLoader,
        CreateTable
    },
    props: {
        edit: Boolean
    },
    validations: {
        sendData: {
            organization_id: {required},
            provider_contr_agent_id: {required},
            contractor_contr_agent_id: {required},
            customer_object_id: {required},
            work_agreement_id: {},
            responsible_full_name: {required},
            responsible_phone: {required},
            comment: {required},
        }
    },
    computed: {
        ...mapState({
            user: state => state.account.user,
            currentConsignmentNotesRegistry: state => state.consignmentNotesRegistries.currentConsignmentNotesRegistry
        }),
        ...mapGetters({
            isProvider: 'isProvider',
            isContractor: 'isContractor',
            organizationsOptions: 'getOrganizationsOptions',
            contrAgentsOptions: 'getContrAgentsOptions',
            workAgreementsOptions: 'getWorkAgreementsOptions',
            objectsOptions: 'getObjectsOptions',
            subObjectsOptions: 'getSubObjectsOptions'
        })
    },
    watch: {
        'sendData.organization_id'() { this.watchForSendData() },
        'sendData.provider_contr_agent_id'() { this.watchForSendData() },
        'sendData.contractor_contr_agent_id'() { this.watchForSendData() },
        'sendData.work_agreement_id'() { this.watchForSendData() },
        'sendData.customer_object_id'() { this.watchForSendData() },
        'sendData.customer_sub_object_id'() { this.watchForSendData() },
        'sendData.file'() {
            this.fileUrl = null
        }
    },
    data: () => ({
        sendData: {
            action: 'draft',
            organization_id: null,
            provider_contr_agent_id: null,
            contractor_contr_agent_id: null,
            customer_object_id: null,
            customer_sub_object_id: null,
            work_agreement_id: null,
            responsible_full_name: '',
            responsible_phone: '',
            comment: '',
            file: null
        },
        positions: [
            {
                consignment_id: null,
                nomenclature_id: null,
                count: null,
                vat_rate: null,
                amount_with_vat: null,
                amount_without_vat: null,
                price_without_vat: null
            }
        ],
        positionsFields: [
            {
                key: 'consignment_id',
                label: '№ товарной накладной',
                full: 'Номер товарной накладной',
                type: 'select'
            },
            {
                key: 'consignment_date',
                label: 'Дата товарной накладной',
                full: 'Дата товарной накладной',
                type: 'disabled'
            },
            {
                key: 'nomenclature_id',
                label: 'Номенклатура',
                full: 'Номенклатура',
                type: 'select_nomenclature_from_consignment'
            },
            {
                key: 'mnemocode',
                label: 'Мнемокод',
                full: 'Мнемокод',
                type: 'disabled'
            },
            {
                key: 'count',
                label: 'Количество',
                full: 'Количество',
                type: 'number'
            },
            {
                key: 'vat_rate',
                label: 'Ставка НДС',
                full: 'Ставка НДС',
                type: 'select'
            },
            {
                key: 'amount_with_vat',
                label: 'Сумма с НДС',
                full: 'Сумма с НДС',
                type: 'disabled'
            },
            {
                key: 'amount_without_vat',
                label: 'Сумма без НДС',
                full: 'Сумма без НДС',
                type: 'disabled'
            },
            {
                key: 'price_without_vat',
                label: 'Цена без НДС',
                full: 'Цена без НДС',
                type: 'disabled'
            }
        ],
        tabIndex: 0,
        consignments: [],
        count: 0,
        pageLoad: false,
        fileUrl: null
    }),
    methods: {
        deleteConsigmentNoteRegistry () {
            if (this.edit) {
                this.$store.dispatch('deleteConsigmentNoteRegistry', this.$route.params.id)
                    .then(() => {
                        this.$bvToast.toast('Реест товарной накладной успешно удалена', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'success'
                        })
                        this.$router.push('/consignment-notes-registries')
                    })
                    .catch(() => {
                        this.$bvToast.toast('Что-то пошло не так, попробуйте позднее', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'danger'
                        })
                    })
            } else {
                this.$router.push('/consignment-notes')
            }
        },
        createConsigmentNoteRegistry (save = false) {
            if (this.$v.$invalid) {
                this.$v.$touch()
                this.$bvToast.toast('Заполните обязательные поля', {
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
                return
            }
            this.sendData.action = save ? 'draft' : 'approve'
            if (this.edit) {
                this.$store.dispatch('updateConsignmentNoteRegistry', { id: this.$route.params.id, data: {...this.sendData, positions: this.positions} }).then(() => {
                    this.sendSuccess('Успешно')
                }).catch(err => {
                    this.sendError(err)
                })
            } else {
                this.$store.dispatch('createConsignmentNoteRegistry', {...this.sendData, positions: this.positions}).then(() => {
                    this.sendSuccess('Успешно')
                }).catch(err => {
                    this.sendError(err)
                })
            }
        },
        addConsignmentNotesRegistryPosition () {
            this.positions.push({
                consignment_id: null,
                nomenclature_id: null,
                count: null,
                vat_rate: null,
                amount_with_vat: null,
                amount_without_vat: null,
                price_without_vat: null
            })
            this.count++
        },
        watchForSendData() {
            const data = {
                organization_id: this.sendData.organization_id,
                provider_contr_agent_id: this.sendData.provider_contr_agent_id,
                contractor_contr_agent_id: this.sendData.contractor_contr_agent_id,
                work_agreement_id: this.sendData.work_agreement_id,
                customer_object_id: this.sendData.customer_object_id,
                customer_sub_object_id: this.sendData.customer_sub_object_id
            }
            for (let key in data) {
                if (data[key] === null && key !== 'customer_sub_object_id') {
                    return false
                }
            }
            this.getSearchConsignments(data)
        },
        async getSearchConsignments(data) {
            await this.$store.dispatch('getSearchConsignments', data).then(resp => {
                this.consignments = resp

                this.consignments = resp.map(nomenclature => ({
                    id: nomenclature.id,
                    number: nomenclature.number,
                    date: nomenclature.date,
                    nomenclatures: nomenclature.positions.filter(position => position.max_available_count !== 0).map(position => ({
                        id: position.nomenclature.id,
                        name: position.nomenclature.name,
                        count: position.count,
                        mnemocode: position.nomenclature.mnemocode,
                        max_available_count: position.max_available_count,
                        price: position.price_without_vat
                    }))
                })).filter(consignment => consignment.nomenclatures.length)

            })
        },
        sendSuccess(successText) {
            this.$bvToast.toast(successText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'success'
            })
            this.$router.push('/consignment-notes-registries')
        },
        sendError(err) {
            const errText = err.response.status === 422 ? 'Проверьте правильность заполненных полей' : 'Ошибка сервера. Попробуйте позднее.'
            this.$bvToast.toast(errText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'danger'
            })
        },

        getContrAgentsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getContrAgentsOptionsSearch(search, loading, this);
        }
        },
        getContrAgentsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/contr_agents', { params: { name: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'contrAgents',
                data: r.data.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800),

        getObjectsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getObjectsOptionsSearch(search, loading, this);
        }
        },
        getObjectsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/objects', { params: { name: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'objects',
                data: r.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800), 

        getWorkAgreementsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getWorkAgreementsOptionsSearch(search, loading, this);
        }
        },
        getWorkAgreementsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios
            .get("/references/work_agreements", { params: { number: search } })
            .then((r) =>
            vm.$store.commit("setReferenceBooks", {
                book: "workAgreements",
                data: r.data.data,
            })
            )
            .finally(() => loading(false));
        },
        800),

        clearFile() {
            this.fileUrl = null
            this.sendData.file = null
        }
    },
    async mounted() {
        const id = this.$route.params.id
        if (this.isContractor) {
            this.sendData.contractor_contr_agent_id = this.user.contr_agent.id
        }
        if (this.isProvider) {
            this.sendData.provider_contr_agent_id = this.user.contr_agent.id
        }
        if (this.edit) {
            await this.$store.dispatch('getCurrentConsignmentNotesRegistry', id)
            for (let key in this.sendData) {
                this.sendData[key] = this.currentConsignmentNotesRegistry[key]
            }
            this.positions = this.currentConsignmentNotesRegistry.positions
        }
        this.pageLoad = true
    }
}
</script>

<style scoped>

</style>
